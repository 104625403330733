<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-account menu="quoteDetails"></submenu-account>
      
    <div class="submenu-content" id="content">
      <h2 class="pb-4">Quote Details</h2>
      

      <spinner v-if="loading" />
      
      <div v-if="message">
          <div class="alert alert-success">{{message}}</div>
      </div>

      <div v-if="error" class="alert alert-danger">{{error}}</div>
      <div v-else>
        <!-- quote information -->
        <div class="row">
          <div class="col-md-6">
            <div class="mb-2 row">
              <div class="col-md-5 lead" >
                Quote ID:
              </div>
              <div class="col-md-5" >
                {{data.id}}
              </div>
            </div>

            <div class="mb-2 row">
              <div class="col-md-5 lead">
                Status:
              </div>
              <div class="col-md-5">
                {{data.status}}
              </div>
            </div>

            <div class="mb-2 row">
              <div class="col-md-5 lead">
                <span>Quoted Date:</span>
              </div>
              <div class="col-md-5">
                <span>{{data.created_at}}</span>
              </div>
            </div>

            <div class="mb-2 row">
              <div class="col-md-5 lead">
                Valid Until:
              </div>
              <div class="col-md-5">
                <span v-if="data.valid_until">{{data.valid_until}}</span>
                <span v-else>N/A</span>
              </div>
            </div>

            <div class="mb-2 row">
              <div class="col-md-5 lead">
                Ma Labs Sales Rep:
              </div>
              <div class="col-md-5">
                {{data.sales}}
              </div>
            </div>

            <div class="mb-2 row">
              <div class="col-md-5 lead">
                Ma Labs Sales Rep Note:
              </div>
              <div class="col-md-7">
                <span v-if="data.sales_comments">{{data.sales_comments}}</span>
                <span v-else>N/A</span>
              </div>
            </div>

            <div v-if="data.status == 'Converted' && data.webso" class="mb-2 row">
              <div class="col-md-5 lead">
                Web P.O.:
              </div>
              <div class="col-md-5">
                <a :href="'/account/web-order/details/'+data.webso">{{data.webso}}</a>
              </div>
            </div>
            
          </div>

          <div class="col-md-6">
            <div class="mb-2 row">
              <div class="col-md-5 lead">
                Customer ID:
              </div>
              <div class="col-md-5">
                {{data.custno}}
              </div>
            </div>

            <div class="mb-2 row">
              <div class="col-md-5 lead">
                Customer Company:
              </div>
              <div class="col-md-5">
                {{data.company}}
              </div>
            </div>

            <div class="mb-2 row">
              <div class="col-md-5 lead">
                Customer Email:
              </div>
              <div class="col-md-5">
                {{data.email}}
              </div>
            </div>

            <div class="mb-2 row">
              <div class="col-md-5 lead">
                Customer Contact:
              </div>
              <div class="col-md-5">
                {{data.contact}}
              </div>
            </div>

            <div class="mb-2 row">
              <div class="col-md-5 lead">
                Customer Phone:
              </div>
              <div class="col-md-5">
                {{data.phone}}
              </div>
            </div>
          </div>
        </div>

       <!-- Print button -->
       <div class="col">
            <button class="btn btn-success float-end" @click="printQuote"><i class="bi bi-printer"></i> Print Quote</button>
        </div>

        <!-- Item list -->
        <div class="mt-5">
            <table class="table">
              <thead class="table-light">
                <tr>
                  <th width="10%">Item#</th>
                  <th scope="col-md-4">Product Description</th>
                  <th width="10%" >Branch</th>
                  <th width="10%" align="center" class="center">Qty</th>
                  <th width="10%" class="text-end">Quoted Prices</th>
                  <th width="10%" class="text-end">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                
                  <tr v-for="item in data.quote_items">
                    <td>{{item.itmno}}</td>
                    <td class="desc">
                      <div>{{item.desc}}</div>
                        <div class="mt-4">
                            <div class="mt-2 mb-2"><i class="bi bi-wrench-adjustable"></i> <u>Configuration Components</u>: </div> 
                            <table class="table table-condensed table-striped">
                                <tbody>
                                <tr v-for="cp in item.system_details">
                                    <td width="20%" valign="top" class="pe-2">{{cp.itmno}}</td>
                                    <td width="80%">{{cp.desc}} ({{cp.qty}} pcs)  </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                    <td>San Jose</td>
                    <td  align="center">{{item.quantity}}</td>    
                    <td class="text-end">
                      <span v-if="(data.status == 'Approved' || data.status == 'Converted') && item.quoted_price != null">{{formatPrice(item.quoted_price)}}</span>
                      <span v-else>N/A</span>
                    </td>
                    <td class="text-end">
                      <span v-if="(data.status == 'Approved' || data.status == 'Converted') && item.quoted_price != null">{{formatPrice(getSubtotal(item.quoted_price, item.quantity))}}</span>
                      <span v-else>N/A</span>
                    </td>
                  </tr>
                
              </tbody>
            </table>
        </div>
        
        <!-- total prices -->
        <div>
            <table class="col-md-12">
              <tr>
                <td class="text-end fw-bold mr-2$">Total Quoted Price:</td>
                <td width="10%" align="right">
                  <span v-if="(data.status == 'Approved' || data.status == 'Converted') && data.total_quoted_price">{{(formatPrice(data.total_quoted_price))}}</span>
                  <span v-else>N/A</span>
                </td>
              </tr>
            </table>

        </div>
        

        
        <div>
            <!-- company address -->
            <div class="container my-5">
              <div class="col-md-4 lead">
                Company adress:
              </div>
              <div>
                <div>{{data.company}}</div>
                <div><span v-if="data.addr1">{{data.addr1}},&nbsp;</span>
                  <span v-if="data.addr2">{{data.addr2}}</span>
                </div>
                <div>{{data.city}}, {{data.state}} {{data.zipcode}}</div>
                <div>{{data.country}}</div>
                <div v-if="data.bphone">Phone: {{data.bphone}}</div>
              </div> 
            </div>

            <!-- shipping info -->
            <div class="container my-5">
              <div class="col-md-4 lead">
                Shipping Address:
              </div>
              <div>
                <div>{{data.scompany}}</div>
                <div><span v-if="data.saddr1">{{data.saddr1}},&nbsp;</span>
                  <span v-if="data.saddr2">{{data.saddr2}}</span>
                </div>
                <div>{{data.scity}}, {{data.sstate}} {{data.szipcode}}</div>
                <div>{{data.scountry}}</div>
                <div v-if="data.sphone">Phone: {{data.sphone}}</div>
              </div> 
            </div>

            <!-- Delivery Preference -->
            <div class="container my-5">
              <div class="col-md-4 lead">
                Delivery Preference:
              </div>
              <div class="col-md-4">
                {{data.shipcode}}
              </div>
            </div>

            <!-- Special Instruction -->
            <div class="container my-5">
              <div class="col-md-4 lead">
                Special Instruction (Optional):
              </div>
              <div class="col-md-4"> 
                <div>{{data.customer_comments}} </div> 
                <textarea type="text" class="form-control form-control" v-model="customer_note"></textarea>
              </div>
            </div>

            <!-- PO Number -->
            <div class="container my-5">
              <div class="col-md-4 lead">
                PO Number (Optional):
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control form-control" v-model="pono">
              </div>
            </div>
            
            
        </div>

        <!-- buttons -->
        <div class="row my-5">
          <div class="col">
            <button v-if="waiting" class="btn btn-primary me-5" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Convert to Purchase Order
            </button>
            <button v-else class="btn btn-primary me-5" @click="handleConvert" :class="{'btn-secondary': data.status != 'Approved' || valid == false}" :disabled="data.status != 'Approved' || valid == false">Convert to Purchase Order</button>
          </div>
          
        </div>

      </div>
    </div> 
   
  </div>
</template>

<script>
import submenuAccount from '../../components/nav/submenuAccount.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import Spinner from "../../components/Spinner.vue"
import { ref } from 'vue'
import { getAuthAPI } from "../../utils/axios-api"
import { formatPrice } from '../../utils/common'
import { useRouter } from "vue-router"

export default {
  name: "QuoteRequestDetails",
  components: { submenuAccount, Spinner, BreadcrumbPage },
  props: ["id"],
  setup(props) {
    const waiting = ref(false)
    const loading = ref(true)
    const error = ref("")
    const message = ref("")
    const data = ref("")
    const pono = ref("")
    const customer_note = ref("")
    const valid = ref(false)
    

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "My Account", link: ""})
    breadcrumb_links.value.push({text: "Quote Request", link: "QuoteRequest"})
    breadcrumb_links.value.push({text: props.id, link: ""})

    //get quote list
    getAuthAPI.get('/order/quote-request/details/' + props.id)
    .then(response => {
        data.value = response.data
        
        //compare dates
        var today = new Date()
        today = new Date(today.toLocaleDateString())
        var valid_until = new Date(data.value.valid_until)
        if (valid_until >= today) valid.value = true
        
        loading.value = false
    })
    .catch(err => {
      try {
        error.value = err.message
      }
      catch {
        error.value = "Something is wrong."
      }
      loading.value = false
    })
    

    function getSubtotal(price, qty) {
      return price*qty
    }

    function generatePDFContent() {
      let html = '<div><img src="https://local.malabs.com/media/images/logo.jpg"></div>'
      html = html + '<h1>Quote Request</h1>'
      html = html + "<div><strong>Status:</strong> New</div>"
      html = html + "<div><strong>Date/Time:</strong> 11/08.2022 11:11:52</div>"
      html = html + "<table>"
      html = html + "<tr>"
      html = html + "<td><td>"
      html = html + "</tr>"
      html = html + "</table>"
      return html
    }
  
    const handleConvert = async () => {
      waiting.value = true

      //let payload = {"quote_id": "184", "pono": "test", "customer_comments":"test"}
      let payload = {}
      payload["quote_id"] = props.id
      payload["pono"] = pono.value
      payload["customer_comments"] = customer_note.value
      getAuthAPI.post('/order/quote-webpo/', payload)
        .then(response => {
            waiting.value = false
            message.value = response.data.message
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .catch(err => {
            error.value = err.response.data.message
            waiting.value = false
            window.scrollTo({ top: 0, behavior: 'smooth' });

            waiting.value = false
        })
    }

    // function downloadPDF(divId,
    //   title) {
      
    //   let html = generatePDFContent()

    //   let mywindow = window.open('', 'PRINT', 'height=650,width=1500,top=100,left=150');

    //   mywindow.document.write('<html><head><title>${title}</title></head><body>');
    //   mywindow.document.write(html);
    //   mywindow.document.write('</body></html>');

    //   mywindow.document.close(); // necessary for IE >= 10
    //   mywindow.focus(); // necessary for IE >= 10*/


    //   mywindow.print();
    //   mywindow.close();

    //   return true;
    // }

    function downloadPDF(){
      let html = generatePDFContent()
      var a = document.body.appendChild(
          document.createElement("a")
      );
      a.download = "export.html";
      // a.href = "data:text/html," + document.getElementById("content").innerHTML; // Grab the HTML
      a.href = "data:text/html," + html; // Grab the HTML
      a.click(); // Trigger a click on the element
    }

    const router = useRouter()
    function printQuote() {
      router.push({name: 'QuoteRequestPrint', params: {id: props.id} })
    }
    

    return {
        waiting,
        loading,
        error,
        message,
        valid,
        data,
        pono,
        customer_note,
        breadcrumb_links,
        formatPrice,
        getSubtotal,
        handleConvert,
        printQuote }
  }
}
</script>

<style scoped>
.lead{
    color:black;
    font-size: 1.2em;
}
.pointer {cursor: pointer;}
.center {text-align: center;}
</style>